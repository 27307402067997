// This file is automatically generated by `generated_js` task. Any changes will be lost
/* tslint:disable */
export const GoCDVersion = {
  "version": "22.2.0",
  "buildNumber": "14697",
  "gitSha": "4bdda4e0d769e66da651926c7066979740bd7ae7",
  "fullVersion": "22.2.0-14697",
  "formattedVersion": "22.2.0 (14697-4bdda4e0d769e66da651926c7066979740bd7ae7)",
  "copyrightYear": "2022"
}


function stripLeadingPrefix(suffix: string, prefix: string) {
  if (suffix.startsWith(prefix)) {
      suffix = suffix.substring(1);
  }
  return suffix;
}

export function docsUrl(suffix: string = '') {
  return `https://docs.gocd.org/22.2.0/${stripLeadingPrefix(suffix, '/')}`
}

export function apiDocsUrl(suffix: string = '') {
  return `https://api.gocd.org/22.2.0/${stripLeadingPrefix(suffix, '#')}`
}
    
